import mixitup from "mixitup";

var requestURL = "/JSON/donnees.json";
var request = new XMLHttpRequest();
request.open("GET", requestURL);
request.responseType = "json";
request.send();  

var contents = document.querySelector(".contents");
request.onload = function () {
  var data = request.response;
  //   chooseElement(data);
  showProducts(data);

  var config = document.querySelector(".contents");
  mixitup(config, {
    selectors: {
      target: ".mix",
    },
    load: {
      filter: '.pizza'
      },
    animation: {
      duration: 299,
      nudge: true,
      reverseOut: false,
      effects: "fade translateX(20%) translateZ(-44px) rotateX(90deg)",
    },
  });
};

// function chooseElement(jsonObj) {
//   var myH1 = document.createElement("h1");
//   myH1.textContent = jsonObj["squadName"];
//   header.appendChild(myH1);

//   var myPara = document.createElement("p");
//   myPara.textContent = "Hometown :" + jsonObj["homeTown"] + jsonObj["formed"];
//   header.appendChild(myPara);
// }

function showProducts(jsonObj) {
  var allProducts = jsonObj["allProducts"]
  for (var y = 0; y < allProducts.length; y++) {
    var className = allProducts[y].productGroup;
    var products = allProducts[y].products;
    for (var i = 0; i < products.length; i++) {
      var myArticle = document.createElement("div");
      var myH2 = document.createElement("h2");
      var myPrice = document.createElement("p");
      var myRecipe = document.createElement("p");
      myArticle.classList.add("mix");
      myArticle.classList.add(className);
      // myArticle.classList.add("products");
      myPrice.classList.add("price");
      myRecipe.classList.add("description");

      myH2.textContent = products[i].name;
      myRecipe.textContent = products[i].recipies;
      myPrice.textContent = products[i].price + "€";

      myArticle.appendChild(myH2);
      myArticle.appendChild(myRecipe);
      myArticle.appendChild(myPrice);

      contents.appendChild(myArticle);
    }
  }
}
let modal = null;

const openModal = function (e) {
  e.preventDefault();
  const target = document.querySelector(e.target.getAttribute('href'));
  target.style.visibility="visible";
  target.removeAttribute('aria-hidden');
  target.setAttribute('aria-modal', 'true');
  modal = target;
  modal.addEventListener('click', closeModal);
  modal.querySelector('.js-modal-close').addEventListener('click', closeModal)
  modal.querySelector('.js-modal-stop').addEventListener('click', stopPropagation)

}

const closeModal = function (e) {
  if (modal === null) return;
  e.preventDefault();
  modal.style.visibility="hidden";
  modal.removeAttribute('aria-true');
  modal.setAttribute('aria-hidden', 'true');
  modal.removeEventListener('click', closeModal);
  modal.querySelector('.js-modal-close').removeEventListener('click', closeModal)
  modal.querySelector('.js-modal-stop').removeEventListener('click', stopPropagation)
  modal = null;
}

const stopPropagation = function(e){
  e.stopPropagation();
}
document.querySelectorAll('.js-modal').forEach(a => {
  a.addEventListener('click', openModal);
})

window.addEventListener('keydown', function (e) {
  if (e.key === "Escape" || e.key === "Esc"){
    closeModal(e)
  }
})